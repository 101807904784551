<template>
  <div class="page coming-soon">
    <div class="coming-soon-bg-elements">
      <div class="coming-soon-bg-elements__item">
        <svg
          width="43"
          height="43"
          viewBox="0 0 43 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3329 0.206998L27.7276 12.1905L42.949 16.6784L30.9627 27.0653L26.4751 42.2754L16.0801 30.2919L0.863768 25.8054L12.8501 15.4184L17.3329 0.206998Z"
            fill="#EFA800"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6015 0.776241L22.9219 11.898L31.7189 19.4741L20.5973 22.7923L13.0224 31.5848L9.70186 20.463L0.907796 12.8893L12.0294 9.57116L19.6015 0.776241Z"
            fill="#71C1DF"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="43"
          height="43"
          viewBox="0 0 43 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3329 0.207L27.7276 12.1905L42.949 16.6784L30.9627 27.0653L26.4751 42.2754L16.0801 30.2919L0.863768 25.8054L12.8501 15.4184L17.3329 0.207Z"
            fill="#D20C1D"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="43"
          height="43"
          viewBox="0 0 43 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.3329 0.206998L27.7276 12.1905L42.949 16.6784L30.9627 27.0653L26.4751 42.2754L16.0801 30.2919L0.863768 25.8054L12.8501 15.4184L17.3329 0.206998Z"
            fill="#EFA800"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.6015 0.776241L22.9219 11.898L31.7189 19.4741L20.5973 22.7923L13.0224 31.5848L9.70186 20.463L0.907796 12.8893L12.0294 9.57116L19.6015 0.776241Z"
            fill="#71C1DF"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
            fill="#D20C1C"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
            fill="#D20C1C"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
            fill="#D20C1C"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M0.354605 4.03071C1.20326 1.11284 4.25586 -0.563297 7.17103 0.283674C10.0885 1.13131 11.7665 4.18185 10.9178 7.09973C10.0691 10.0176 7.01649 11.6936 4.09901 10.846C1.18384 9.99903 -0.494049 6.94858 0.354605 4.03071Z"
            fill="#61BADB"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
            fill="#EFA800"
          />
        </svg>
      </div>
      <div class="coming-soon-bg-elements__item">
        <svg
          width="7"
          height="7"
          viewBox="0 0 7 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.9"
            d="M1.0528 2.63016C1.5157 1.0386 3.18075 0.124344 4.77085 0.586328C6.3622 1.04868 7.27744 2.71261 6.81454 4.30418C6.35164 5.89574 4.68655 6.80994 3.0952 6.3476C1.5051 5.88561 0.589894 4.22173 1.0528 2.63016Z"
            fill="#EFA800"
          />
        </svg>
      </div>
    </div>
    <div class="container">
      <div class="coming-soon__content">
        <img
          src="../assets/images/product.png"
          alt=""
          class="coming-soon__img _left"
        />
        <div class="coming-soon__title">История скоро <br />начнется!</div>
        <div class="coming-soon__text">
          Интерактивные истории <br />с подарками появятся на сайте 1 мая
        </div>
        <div class="counter-wrapper">
          <div class="counter-title">до начала акции осталось</div>
          <div class="counter">
            <div class="counter__item">
              <div class="counter__number" v-html="days"></div>
              <span>{{ daysText }}</span>
            </div>
            <div class="counter__item">
              <div class="counter__number" v-html="hours"></div>
              <span>{{ hoursText }}</span>
            </div>
            <div class="counter__item">
              <div class="counter__number" v-html="minutes"></div>
              <span>{{ minutesText }}</span>
            </div>
          </div>
        </div>
        <img
          src="../assets/images/ill.png"
          alt=""
          class="coming-soon__img _right"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      days: null,
      daysText: null,
      hoursText: null,
      minutesText: null,
      hours: null,
      minutes: null,
      seconds: null,
      isEnded: null,
      endDate: new Date("2024-04-30T23:59:21.817Z"),
    };
  },
  methods: {
    wordVariant(i, ent, v1, v2, v3) {
      const items = Number(i);
      this[ent] =
        items === 1 ||
        items === 21 ||
        items === 31 ||
        items === 41 ||
        items === 51
          ? v1
          : (items > 1 && 5 > items) || (items > 21 && 25 > items)
          ? v2
          : v3;
    },
    updateRemaining(distance) {
      const daysNum = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hoursNum = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutesNum = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );

      this.days = `<span class='num'>${
        daysNum.toString().charAt(1) ? daysNum.toString().charAt(0) : 0
      }</span><span class='num'>${
        daysNum.toString().charAt(1) || daysNum.toString().charAt(0)
      }</span>`;
      this.wordVariant(daysNum, "daysText", "день", "дня", "дней");

      this.hours = `<span class='num'>${
        hoursNum.toString().charAt(1) ? hoursNum.toString().charAt(0) : 0
      }</span><span class='num'>${
        hoursNum.toString().charAt(1) || hoursNum.toString().charAt(0)
      }</span>`;
      this.wordVariant(hoursNum, "hoursText", "час", "часа", "часов");

      this.minutes = `<span class='num'>${
        minutesNum.toString().charAt(1) ? minutesNum.toString().charAt(0) : 0
      }</span><span class='num'>${
        minutesNum.toString().charAt(1) || minutesNum.toString().charAt(0)
      }</span>`;
      this.wordVariant(minutesNum, "minutesText", "минута", "минуты", "минут");
    },

    tick() {
      const currentTime = new Date();
      const distance = Math.max(this.endDate - currentTime, 0);
      this.updateRemaining(distance);

      if (distance === 0) {
        clearInterval(this.timer);
        this.isEnded = true;
      }
    },
    checkAuth() {
      if (this.user) {
        this.$modal.show("code_registration");
      } else {
        this.$modal.show("signup");
      }
    },
  },
  mounted() {
    this.$parent.showFooter = false;
    this.$parent.showHeader = false;
    this.tick();
    this.timer = setInterval(this.tick.bind(this), 1000);
  },
  destroy() {
    clearInterval(this.timer);
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="scss">
.coming-soon {
  &.page {
    display: flex;
    padding-top: rem(20px);
    padding-bottom: rem(20px);
    min-height: 100vh;
    align-items: center;
    background: #faf5ed;
    letter-spacing: -0.02em;
    &::before {
      display: none;
    }
  }
  &-bg-elements {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;
    &__item {
      position: absolute;
      display: block;
      animation: rotare 4s linear infinite forwards normal;
      svg {
        animation: blinkSmooth 2s infinite forwards alternate;
      }
      &:nth-child(1) {
        top: 20%;
        left: 10%;
      }
      &:nth-child(2) {
        top: 4%;
        left: 59%;
      }
      &:nth-child(3) {
        top: 80%;
        left: 87%;
      }
      &:nth-child(4) {
        top: 9%;
        left: 70%;
      }
      &:nth-child(5) {
        top: 87%;
        left: 20%;
        @media (min-width: $md) {
          left: 41%;
        }
      }
      &:nth-child(6) {
        top: 55%;
        left: 33%;
      }
      &:nth-child(7) {
        top: 8%;
        left: 17%;
      }
      &:nth-child(8) {
        top: 89%;
        left: 60%;
      }
      &:nth-child(9) {
        top: 80%;
        left: 12%;
      }
      &:nth-child(10) {
        top: 12%;
        left: 43%;
      }
      &:nth-child(11) {
        top: 7%;
        left: 95%;
      }
      &:nth-child(2n) {
        animation-delay: 0.5s;
        svg {
          animation-delay: 0.5s;
        }
      }
      &:nth-child(3n) {
        animation-delay: 0.7s;
        svg {
          animation-delay: 0.7s;
        }
      }
      &:nth-child(4n) {
        animation-delay: 1s;
        svg {
          animation-delay: 1s;
        }
      }
    }
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: rem(806px);
    padding: rem(116px) rem(18px);
    max-width: 100%;
    margin: auto;
    background: #fff;
    border-radius: rem(54px);
  }
  &__title {
    margin-bottom: rem(10px);
    color: #52245d;
    font-size: rem(36px);
    line-height: rem(38px);
  }
  &__text {
    color: #52245d;
    margin-bottom: rem(20px);
    font-size: rem(16px);
    line-height: rem(19px);
    font-family: $font-reg;
  }
  &__img {
    position: absolute;
    &._left {
      top: rem(-50px);
      height: rem(145px);
    }
    &._right {
      bottom: rem(-76px);
      height: rem(172px);
    }
  }
  &__btn {
    &-wrapper {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-top: vh(63px);
    }
  }
  &__info {
    width: rem(24px);
    margin-right: rem(-24px);
    margin-bottom: vh(47px);
    margin-left: rem(3px);
  }
  @media (min-width: $md) {
    &.page {
      height: 100vh;
    }
    &__content {
      padding: rem(50px);
    }
    &__img {
      &._left {
        height: rem(403px);
        top: vh(134px);
        right: 100%;
        transform: translateX(50%);
      }
      &._right {
        top: vh(-21px);
        height: rem(386px);
        left: 100%;
        transform: translateX(-50%);
      }
    }
    &__title {
      font-size: rem(48px);
      line-height: rem(50px);
      margin-bottom: rem(20px);
    }
    &__text {
      margin-bottom: rem(20px);
      font-size: rem(24px);
      line-height: rem(29px);
    }
    &__btn {
      &-wrapper {
        width: max-content;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-top: vh(100px);
      }
    }
    &__info {
      margin-bottom: rem(47px);
      margin-left: rem(3px);
      margin-right: 0;
    }
  }
  .counter {
    display: flex;
    justify-content: center;
    &-title {
      text-align: left;
      font-size: rem(9px);
    }
    &-wrapper {
      padding: rem(14px) rem(23px);
      background: #e73932;
      box-shadow: rem(6px) rem(6px) 0 #c6302a;
      border-radius: rem(22px);
      color: #fff;
      font-family: $font-light;
    }
    &__item {
      position: relative;
      text-align: center;
      &:not(:last-child) {
        margin-right: rem(20px);
        &::before {
          content: ":";
          position: absolute;
          top: 40%;
          transform: translateY(-50%);
          left: calc(100% + rem(10px));
          display: block;
          color: #fff;
          line-height: 1;
          animation: blink 1s steps(5, start) infinite;
        }
      }
      span {
        display: block;
        font-size: rem(11px);
        line-height: 1;
        opacity: 0.5;
      }
    }
    &__number {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(60px);
      height: rem(60px);
      padding-top: vh(0px);
      // padding-left: rem(3px);
      // letter-spacing: 0.3em;
      .num {
        display: block;
        flex: 50%;
        font-size: rem(51px);
        line-height: 1;
        text-align: center;
        opacity: 1;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: url("../assets/images/counter-bg.png") no-repeat center;
        background-size: contain;
        z-index: -1;
      }
    }
    @media (min-width: $md) {
      justify-content: flex-start;
      &-title {
        font-size: rem(11px);
      }
      &-wrapper {
        padding: rem(14px) rem(20px);
      }
      &__item {
        &:not(:last-child) {
          margin-right: rem(30px);
          &::before {
            left: calc(100% + rem(11px));
            font-size: rem(37px);
          }
        }
        span {
          font-size: rem(11px);
          line-height: 1;
        }
      }
      &__number {
        width: rem(60px);
        height: rem(60px);
        padding-top: vh(0px);
        .num {
          font-size: rem(51px);
        }
      }
    }
  }
}
@keyframes blink {
  to {
    visibility: hidden;
  }
}
@keyframes blinkSmooth {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
    transform: scale(1.2);
  }
}
@keyframes rotare {
  to {
    transform: rotate(90deg);
  }
}
</style>
