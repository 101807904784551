var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-page-form__block"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitSendCode()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],attrs:{"type":"hidden","name":"phone"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing)return;_vm.phoneNumber=$event.target.value}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.password) || this.errorMessage.password,
      },attrs:{"label":"Введи пароль","placeholder":"••••","type":"password","field":_vm.password,"autocomplete":"current-password","error":(!_vm.$v.password.required && _vm.$v.password.$error
          ? 'Обязательное поле'
          : !_vm.$v.password.minLength && _vm.$v.password.$error
          ? 'Минимум ' + _vm.$v.password.$params.minLength.min + ' символов'
          : '') || this.errorMessage.password},on:{"input":function($event){_vm.password = $event}}}),_c('div',{staticClass:"form-page-form__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"submit"}},[_vm._v(" Отправить ")]),_vm._m(0)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-page__another-option"},[_c('img',{attrs:{"src":require("@/assets/images/icons/rotation-right.svg"),"alt":""}}),_c('span',[_vm._v("Отправить повторно")])])
}]

export { render, staticRenderFns }