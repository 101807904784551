<template>
  <div class="form-page">
    <div @click="$router.go(-1)" class="form-page__back hidden-xs">
      <img src="../../assets/images/icons/arrow-back.svg" alt="" />
      <span>Назад</span>
    </div>
    <transition name="fade">
      <div v-if="show" class="form-page-form-wrapper">
        <div class="form form-page-form">
          <div class="form-page__title">Введите код</div>
          <enter-code-form />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import EnterCodeForm from "../../components/form/EnterCodeForm.vue";
export default {
  components: { EnterCodeForm },
  data: () => ({
    show: false,
  }),
  mounted() {
    this.$parent.showFooter = false;
    this.show = true;
    if (this.$store.getters.endTime) {
      this.$modal.show("temporarily_unavailable");
    }
  },
};
</script>
