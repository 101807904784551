<template>
  <div class="form-page-form__block">
    <form @submit.prevent="submitSendCode()">
      <input type="hidden" name="phone" v-model="phoneNumber" />
      <inputText
        label="Введи пароль"
        placeholder="••••"
        type="password"
        :field="password"
        autocomplete="current-password"
        :class="{
          error: validationStatus($v.password) || this.errorMessage.password,
        }"
        :error="
          (!$v.password.required && $v.password.$error
            ? 'Обязательное поле'
            : !$v.password.minLength && $v.password.$error
            ? 'Минимум ' + $v.password.$params.minLength.min + ' символов'
            : '') || this.errorMessage.password
        "
        @input="password = $event"
      />
      <div class="form-page-form__footer">
        <button
          class="btn btn--primary"
          type="submit"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          Отправить
        </button>
        <div class="form-page__another-option">
          <img src="@/assets/images/icons/rotation-right.svg" alt="" />
          <span>Отправить повторно</span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  props: ["phone", "formData"],
  components: {
    inputText,
  },
  data() {
    return {
      ready: false,
      password: null,
      phoneNumber: this.phone,
      errorMessage: {
        phoneNumber: null,
        password: null,
      },

      submitStatus: null,
    };
  },
  validations: {
    phoneNumber: {
      required,
    },
    password: {
      required,
      minLength: minLength(4),
    },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        phoneNumber: null,
        password: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    submitSendCode() {
      console.log("submit");
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const formData = this.formData;
        this.$store
          .dispatch("SignUp", {
            login: formData.login,
            name: formData.name,
            surname: formData.surname,
            phone: this.phoneNumber,
            password: this.password,
            city: formData.city,
            rules1: formData.rules1,
            rules2: formData.rules2,
          })
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.submitStatus = null;
              let fieldsError = null;
              if (r.error && r.error.phone) {
                [this.errorMessage.phoneNumber] = r.error.phone;
                fieldsError = true;
              }
              if (r.error && r.error.password) {
                [this.errorMessage.password] = r.error.password;
                fieldsError = true;
              }
            } else {
              this.submitStatus = null;

              this.$modal.hide("enter_code");
              this.$modal.show("success_signup");
              this.$store
                .dispatch("SignIn", {
                  login: this.phoneNumber,
                  password: this.password,
                })
                .then((r) => {
                  if (!r.result) {
                    this.$modal.show("common_error", {
                      list: Object.values(r.error),
                    });
                  } else {
                    this.$store.commit("SET_TOKEN", r.data.auth_key);
                    this.$store.dispatch("GetProfile").then((res) => {
                      if (!res.result) {
                        this.$router.push("/");
                      } else {
                        this.$root.$emit("onLogined");
                        this.$router.push("/profile");
                        this.$modal.hide("authorization");
                      }
                    });
                  }
                });
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
