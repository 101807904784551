var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-page-form__block"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitSendCode()}}},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.password) || this.errorMessage.password,
      },attrs:{"label":"Введите новый пароль","placeholder":"••••","type":"password","field":_vm.password,"autocomplete":"new-password","error":(!_vm.$v.password.required && _vm.$v.password.$error
          ? 'Обязательное поле'
          : !_vm.$v.password.minLength && _vm.$v.password.$error
          ? 'Минимум ' + _vm.$v.password.$params.minLength.min + ' символов'
          : '') || _vm.errorMessage.password},on:{"input":function($event){_vm.password = $event}}}),_c('inputText',{class:{
        error:
          _vm.validationStatus(_vm.$v.passwordRep) || this.errorMessage.passwordRep,
      },attrs:{"label":"Подтвердите новый пароль","placeholder":"••••","type":"password","field":_vm.passwordRep,"autocomplete":"new-password","error":(!_vm.$v.passwordRep.required && _vm.$v.passwordRep.$error
          ? 'Обязательное поле'
          : !_vm.$v.passwordRep.minLength && _vm.$v.passwordRep.$error
          ? 'Минимум ' + _vm.$v.passwordRep.$params.minLength.min + ' символов'
          : '') || _vm.errorMessage.passwordRep},on:{"input":function($event){_vm.passwordRep = $event}}}),_c('div',{staticClass:"form-page-form__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"submit"}},[_vm._v(" Сменить ")]),_c('div',{staticClass:"form-page__another-option"},[_c('span',[_vm._v("Не менять пароль? ")]),_c('router-link',{staticClass:"_undeline-link",attrs:{"to":"/"}},[_vm._v("Пропустить")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }