<template>
  <label
    class="form__field"
    :class="
      color === 'white'
        ? 'form__field--white'
        : '' || color === 'blue'
        ? 'form__field--blue'
        : ''
    "
  >
    <div class="form__caption" v-if="label">{{ label }}</div>
    <input
      v-if="maskValue != ''"
      :disabled="isDisabled"
      :type="fieldType"
      :value="value"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :autocompletetype="autocompletetype"
      class="form__field-input"
      :class="size ? 'form__field-input--' + size : ''"
      v-model.trim="retValueMask"
      v-mask="maskValue"
      :name="name"
      @focus="errorReset()"
      @input="onInputMask()"
      @blur="onBlurMask()"
    />
    <input
      v-else
      :disabled="isDisabled"
      :type="fieldType"
      :value="value"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :autocompletetype="autocompletetype"
      class="form__field-input"
      :class="size ? 'form__field-input--' + size : ''"
      v-model.trim="retValue"
      :name="name"
      @focus="errorReset()"
      @input="onInput()"
      @blur="onBlur()"
    />
    <div v-if="type === 'password'" @click="toggleType" class="form__toggle">
      <img
        v-if="fieldType === 'password'"
        src="../../assets/images/icons/eye.svg"
        alt=""
      />
      <img v-else src="../../assets/images/icons/eye-off.svg" alt="" />
    </div>
    <div class="error-hint" v-if="error">{{ error }}</div>
  </label>
</template>
<script>
export default {
  data() {
    return {
      fieldType: "text",
      retValue: this.field,
      retValueMask: this.field,
      maskValue: this.mask || "",
      nameValue: this.name || "",
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },

    onBlur() {
      this.$emit("input", this.retValue);
    },
    onInputMask() {
      const str = this.retValueMask
        ? this.retValueMask.split(" ").join("")
        : null;
      this.$emit("input", str);
    },

    onBlurMask() {
      const str = this.retValueMask
        ? this.retValueMask.split(" ").join("")
        : null;
      this.$emit("input", str);
    },

    toggleType() {
      if (this.fieldType === "password") {
        this.fieldType = "text";
      } else {
        this.fieldType = "password";
      }
    },
  },
  props: {
    field: String,
    mask: String,
    name: String,
    autocomplete: String,
    autocompletetype: String,
    label: {
      type: String,
    },
    error: {
      type: [String, Boolean],
    },
    value: [String, Number],
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: () => {
        return "text";
      },
    },
    isDisabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    size: {
      type: String,
      default: () => {
        return "sm";
      },
    },
    color: {
      type: String,
      default: () => {
        return "white";
      },
    },
  },
  mounted() {
    this.fieldType = this.type;
  },
  components: {},
};
</script>
<style lang="scss" scoped>
.form__field--white {
  width: 100%;
  margin-bottom: rem(20px);
  @media (min-width: $md) {
    margin-bottom: rem(20px);
  }
}

.form__field-input {
  width: 100%;
  max-width: 100%;
  height: rem(46px);
  appearance: none;
  display: block;
  background: #fff;
  border: none;
  box-sizing: border-box;
  border-radius: rem(45px);
  padding: 0 rem(25px);
  font-size: rem(16px);
  line-height: 2.2rem;
  border: 1px solid rgba($grey, 0.4);
  font-family: $font-reg;
  transition: 0.4s;
  outline: none;
  color: $grey;
  &::placeholder {
    color: rgba($grey, 0.2);
    transition: 0.4s;
  }
  &:focus {
    &::placeholder {
      color: transparent;
    }
  }
  &:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    border: 1px solid $grey;
  }
  @media (min-width: $md) {
    height: rem(48px);
    padding: 0 rem(20px);
    font-size: $field-font-size-lg;
  }
}
</style>
