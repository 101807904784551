<template>
  <div class="sun">
    <svg
      width="277"
      height="252"
      viewBox="0 0 277 252"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g class="rotate">
        <path
          d="M37.7888 102.244L44.2314 106.966C54.5457 115.171 53.6905 131.935 42.9731 139.597L36.4077 144.292L44.0657 146.841C56.5568 150.999 61.5926 166.01 54.1491 176.898L48.9983 181.797L59.7667 181.965C72.9287 181.592 79.847 193.487 76.5419 206.276L73.2962 216.283L82.8306 212.982C95.0585 208.126 107.037 213.587 108.307 226.721L107.83 237.284L115.201 231.895C125.041 223.108 141.898 226.6 147.563 238.535L151.017 245.81L154.864 238.67C161.12 227.064 176.717 224.708 186.105 233.953L191.878 239.639L193.042 231.655C194.949 218.583 208.817 211 220.801 216.48L228.119 219.827L226.497 211.898C223.853 198.962 234.305 187.096 247.423 188.139L255.465 188.778L251.232 181.891C244.324 170.649 250.083 155.902 262.76 152.366L270.525 150.2L264.208 145.173C253.896 136.968 254.298 121.133 265.016 113.47L271.581 108.777L263.923 106.228C251.432 102.07 246.396 87.0579 253.84 76.171L258.401 69.4986L250.363 69.7256C237.201 70.0998 227.357 57.7004 230.66 44.9117L232.678 37.1005L225.158 40.0876C212.931 44.9432 199.471 36.6869 198.2 23.5506L197.419 15.473L191.421 20.8294C181.581 29.6141 166.09 26.468 160.426 14.5343L156.972 7.2595L153.125 14.3976C146.869 26.0055 131.272 28.3596 121.884 19.1144L116.111 13.4285L114.947 21.4121C113.04 34.4859 99.1702 42.069 87.1879 36.5894L78.8677 33.1568L81.4903 41.17C84.136 54.1055 73.6836 65.9733 60.5658 64.9304L52.524 64.2909L56.7566 71.1781C63.6647 82.4186 57.9064 97.1654 45.2292 100.703L37.7888 102.244Z"
          fill="#EFA800"
        />
        <path
          d="M37.7888 102.244L44.2314 106.966C54.5457 115.171 53.6905 131.935 42.9731 139.597L36.4077 144.292L44.0657 146.841C56.5568 150.999 61.5926 166.01 54.1491 176.898L48.9983 181.797L59.7667 181.965C72.9287 181.592 79.847 193.487 76.5419 206.276L73.2962 216.283L82.8306 212.982C95.0585 208.126 107.037 213.587 108.307 226.721L107.83 237.284L115.201 231.895C125.041 223.108 141.898 226.6 147.563 238.535L151.017 245.81L154.864 238.67C161.12 227.064 176.717 224.708 186.105 233.953L191.878 239.639L193.042 231.655C194.949 218.583 208.817 211 220.801 216.48L228.119 219.827L226.497 211.898C223.853 198.962 234.305 187.096 247.423 188.139L255.465 188.778L251.232 181.891C244.324 170.649 250.083 155.902 262.76 152.366L270.525 150.2L264.208 145.173C253.896 136.968 254.298 121.133 265.016 113.47L271.581 108.777L263.923 106.228C251.432 102.07 246.396 87.0579 253.84 76.171L258.401 69.4986L250.363 69.7256C237.201 70.0998 227.357 57.7004 230.66 44.9117L232.678 37.1005L225.158 40.0876C212.931 44.9432 199.471 36.6869 198.2 23.5506L197.419 15.473L191.421 20.8294C181.581 29.6141 166.09 26.468 160.426 14.5343L156.972 7.2595L153.125 14.3976C146.869 26.0055 131.272 28.3596 121.884 19.1144L116.111 13.4285L114.947 21.4121C113.04 34.4859 99.1702 42.069 87.1879 36.5894L78.8677 33.1568L81.4903 41.17C84.136 54.1055 73.6836 65.9733 60.5658 64.9304L52.524 64.291L56.7566 71.1781C63.6647 82.4186 57.9064 97.1654 45.2292 100.703L37.7888 102.244Z"
          stroke="#EFA800"
          stroke-width="3.44206"
          stroke-miterlimit="10"
        />
        <path
          d="M33.8056 100.85L40.135 105.858C50.4705 114.034 50.1127 129.87 39.4188 137.563L32.8661 142.276L40.5301 144.803C53.0319 148.926 58.112 163.922 50.6985 174.832L46.1555 181.518L54.192 181.267C67.3543 180.856 77.2336 193.227 73.965 206.024L71.9701 213.842L79.482 210.834C91.6965 205.943 105.178 214.162 106.486 227.294L107.291 235.369L113.274 229.997C123.087 221.182 138.588 224.285 144.287 236.204L147.76 243.469L151.587 236.32C157.81 224.694 173.4 222.295 182.814 231.513L188.604 237.183L189.744 229.195C191.614 216.118 205.462 208.496 217.461 213.942L224.788 217.266L223.145 209.343C220.464 196.416 230.882 184.52 244.004 185.524L252.047 186.141L247.796 179.267C240.856 168.045 246.572 153.281 259.239 149.709L266.998 147.521L260.669 142.513C250.333 134.335 250.691 118.501 261.385 110.807L267.938 106.095L260.274 103.567C247.77 99.4458 242.692 84.4472 250.105 73.539L254.648 66.8534L246.612 67.1045C233.449 67.515 223.57 55.1445 226.839 42.345L228.834 34.5295L221.322 37.537C209.107 42.4267 195.626 34.2092 194.318 21.0776L193.513 13.001L187.53 18.3741C177.717 27.187 162.216 24.0862 156.517 12.1677L153.044 4.90214L149.217 12.0516C142.994 23.6773 127.404 26.076 117.99 16.8577L112.2 11.188L111.06 19.174C109.19 32.2534 95.3416 39.8755 83.3433 34.4292L76.0162 31.1033L77.6584 39.0266C80.34 51.9556 69.9213 63.8515 56.7997 62.845L48.7571 62.2298L53.0082 69.1041C59.9483 80.3262 54.2318 95.0888 41.5644 98.6625L33.8056 100.85Z"
          fill="#FFE100"
        />
      </g>
      <path
        d="M76.9378 109.171C68.7651 150.337 95.3985 190.365 136.425 198.576C177.452 206.787 217.337 180.072 225.508 138.906C233.681 97.7405 207.047 57.7114 166.021 49.502C124.994 41.2908 85.1087 68.0057 76.9378 109.171Z"
        fill="#AB4D00"
      />
      <path
        d="M154.02 199.985C195.824 198.445 228.461 163.191 226.916 121.243C225.371 79.2943 190.23 46.5364 148.426 48.0759C106.621 49.6155 73.9848 84.8695 75.5296 126.818C77.0745 168.766 112.216 201.524 154.02 199.985Z"
        stroke="#AB4D00"
        stroke-width="4.81977"
        stroke-miterlimit="10"
      />
      <path
        d="M77.0126 106.283C68.9678 146.811 95.1878 186.218 135.579 194.303C175.969 202.385 215.235 176.085 223.281 135.556C231.326 95.0281 205.106 55.6208 164.715 47.5382C124.325 39.4555 85.059 65.7557 77.0126 106.283Z"
        fill="#EFA800"
      />
      <path
        d="M108.225 69.3412C113.459 65.0193 119.386 61.5627 125.733 59.1746"
        stroke="white"
        stroke-width="3.44206"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M94.3454 159.904C83.4388 142.014 80.832 119.112 87.5892 99.2564C89.5264 93.5593 92.2424 88.1322 95.6873 83.203"
        stroke="white"
        stroke-width="3.44206"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <g class="face">
        <path
          d="M169.244 106.081C169.244 106.081 157.999 132.733 143.388 133.601C130.583 134.363 124.129 114.631 129.807 93.0345"
          stroke="#AB4D00"
          stroke-width="4.81977"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M161.851 88.4552C164.014 85.3478 168.435 84.0664 171.917 85.5349C175.4 87.0051 177.58 91.0729 176.879 94.798"
          stroke="#AB4D00"
          stroke-width="4.81977"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M131.781 75.7091C133.944 72.6017 138.364 71.3203 141.847 72.7888C145.33 74.259 147.509 78.3269 146.809 82.0519"
          stroke="#AB4D00"
          stroke-width="4.81977"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sun {
  svg {
    width: 100%;
    height: 100%;
  }
}
.rotate {
  transform-origin: 55% 50%;
  animation: rotate 20s linear infinite forwards;
}
.face {
  animation: face 2s ease-in-out infinite alternate;
}
</style>
