<template>
  <div class="form-page">
    <transition name="fade">
      <div
        v-if="show"
        class="form-page-form-wrapper form-page-form-wrapper--success"
      >
        <div class="form-page__success">
          <div class="form-page__title _centered">Подтвердите <br />почту</div>
          <div class="form-page__text">
            Для завершения регистрации Вам необходимо подтвердить почту, перейдя
            по&nbsp;ссылке в&nbsp;письме
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({
    show: false,
  }),
  mounted() {
    this.$parent.showFooter = false;
    this.show = true;
  },
};
</script>

<style lang="sass" scoped>
.form-page-form-wrapper
  @media (orientation: portrait)
    justify-content: center

.form-page__text
  @media (orientation: portrait)
    font-size: inherit
</style>
