<template>
  <Modal
    name="temporarily_unavailable"
    title="Благодарим <br>за Участие в Акции!"
    size="lg"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <p class="modal__text success_modal__text">
        Период регистрации кассовых чеков завершился 30&nbsp;июня 2024 года
      </p>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {};
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen() {},
  },
};
</script>
