import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ComingSoon from "../views/ComingSoon.vue";

import Login from "../views/account/Login.vue";
import Recover from "../views/account/Recover.vue";
import Signup from "../views/account/Signup.vue";
import SignupEnterCode from "../views/account/SignupEnterCode.vue";
import SuccessfulSignup from "../views/account/SuccessfulSignup.vue";
import FinishSignup from "../views/account/FinishSignup.vue";
import EnterCode from "../views/account/EnterCode.vue";
import NewPassword from "../views/account/NewPassword.vue";
import UserInfo from "../views/account/UserInfo.vue";

import { isPortrait, isMobile, getOffset } from "../utils/device";

Vue.use(VueRouter);

const titlePrefix = "Kinder Россия";

const routes = [
  {
    path: "/",
    // redirect: '/',
    name: "Home",
    component: Home,
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/account/Profile.vue"),
    meta: {
      title: titlePrefix,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: titlePrefix + " Авторизация",
    },
  },
  {
    path: "/recover",
    name: "Recover",
    component: Recover,
    meta: {
      title: titlePrefix + " Восстановить пароль",
    },
  },
  {
    path: "/enter-code",
    name: "EnterCode",
    component: EnterCode,
    meta: {
      title: titlePrefix + " Введите код",
    },
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: NewPassword,
    meta: {
      title: titlePrefix + " Создать новый пароль",
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      title: titlePrefix + " Регистрация",
    },
  },
  {
    path: "/signup-enter-code",
    name: "SignupEnterCode",
    component: SignupEnterCode,
    meta: {
      title: titlePrefix + " Регистрация",
    },
  },
  {
    path: "/user-info",
    name: "UserInfo",
    component: UserInfo,
    meta: {
      title: titlePrefix + " Мои данные",
    },
  },
  {
    path: "/successful-signup",
    name: "SuccessfulSignup",
    component: SuccessfulSignup,
    meta: {
      title: titlePrefix + " Регистрация прошла успешно",
    },
  },
  {
    path: "/finish-signup",
    name: "FinishSignup",
    component: FinishSignup,
    meta: {
      title: titlePrefix + " Подтвердите почту",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        offset: {
          y: 0,
        },
      };
    }
    return { x: 0, y: 0 }; // Go to the top of the page if no hash
  },
});
export default router;
