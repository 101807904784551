<template>
  <div class="form-page">
    <div @click="$router.go(-1)" class="form-page__back hidden-xs">
      <img src="../../assets/images/icons/arrow-back.svg" alt="" />
      <span>Назад</span>
    </div>
    <transition name="fade">
      <div v-if="show" class="form-page-form-wrapper">
        <div class="form form-page-form">
          <div class="form-page__title">Мои данные</div>
          <UserInfoForm />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import UserInfoForm from "../../components/form/UserInfoForm.vue";
export default {
  components: { UserInfoForm },
  data: () => ({
    show: false,
  }),
  mounted() {
    this.show = true;
    if (this.$store.getters.endTime) {
      this.$modal.show("temporarily_unavailable");
    }
  },
};
</script>
