<template>
  <div class="form-page-form__block">
    <form @submit.prevent="submitSendCode()">
      <inputText
        label="Введите новый пароль"
        placeholder="••••"
        type="password"
        :field="password"
        autocomplete="new-password"
        :class="{
          error: validationStatus($v.password) || this.errorMessage.password,
        }"
        :error="
          (!$v.password.required && $v.password.$error
            ? 'Обязательное поле'
            : !$v.password.minLength && $v.password.$error
            ? 'Минимум ' + $v.password.$params.minLength.min + ' символов'
            : '') || errorMessage.password
        "
        @input="password = $event"
      />
      <inputText
        label="Подтвердите новый пароль"
        placeholder="••••"
        type="password"
        :field="passwordRep"
        autocomplete="new-password"
        :class="{
          error:
            validationStatus($v.passwordRep) || this.errorMessage.passwordRep,
        }"
        :error="
          (!$v.passwordRep.required && $v.passwordRep.$error
            ? 'Обязательное поле'
            : !$v.passwordRep.minLength && $v.passwordRep.$error
            ? 'Минимум ' + $v.passwordRep.$params.minLength.min + ' символов'
            : '') || errorMessage.passwordRep
        "
        @input="passwordRep = $event"
      />
      <div class="form-page-form__footer">
        <button
          class="btn btn--primary"
          type="submit"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          Сменить
        </button>
        <div class="form-page__another-option">
          <span>Не менять пароль?&nbsp;</span>
          <router-link to="/" class="_undeline-link">Пропустить</router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  props: ["token"],
  components: {
    inputText,
  },
  data() {
    return {
      ready: false,
      password: null,
      passwordRep: null,
      errorMessage: {
        password: null,
        passwordRep: null,
      },

      submitStatus: null,
    };
  },
  validations: {
    passwordRep: {
      required,
    },
    password: {
      required,
      minLength: minLength(4),
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        passwordRep: null,
        password: null,
      };
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    submitSendCode() {
      console.log("submit");
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const formData = this.formData;
        this.$store
          .dispatch("ResetPassword", {
            passwordNew: this.password,
            passwordNewRepeat: this.passwordRep,
            token: this.token,
          })
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.passwordRep) {
                [this.errorMessage.passwordRep] = r.error.passwordRep;
                fieldsError = true;
              }
              if (r.error && r.error.password) {
                [this.errorMessage.password] = r.error.password;
                fieldsError = true;
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! " + r.error,
                });
              }
            } else {
              this.submitStatus = null;
              this.$modal.show("common_success", {
                title: "изменение пароля",
                text: "ваш пароль изменен",
              });
              this.$store.dispatch("GetProfile").then((res) => {
                if (!res.result) {
                  this.$router.push("/");
                } else {
                  this.$root.$emit("onLogined");
                  this.$router.push({ name: "Profile" });
                }
              });
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
