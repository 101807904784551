var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback"},[_c('form',{key:_vm.indexForm,staticClass:"feedback-form"},[_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.name) || this.errorMessage.name,
      },attrs:{"label":"Имя","field":_vm.name,"error":(!_vm.$v.name.required && _vm.$v.name.$error
          ? 'Обязательное поле'
          : !_vm.$v.name.rus && _vm.$v.name.$error
          ? 'Введите текст на русском языке'
          : '') || this.errorMessage.name},on:{"input":function($event){_vm.name = $event}}}),_c('inputText',{class:{
        error: _vm.validationStatus(_vm.$v.email) || this.errorMessage.email,
      },attrs:{"label":"E-mail","field":_vm.email,"error":(!_vm.$v.email.required && _vm.$v.email.$error
          ? 'Обязательное поле'
          : !_vm.$v.email.email && _vm.$v.email.$error
          ? 'Некорректный e-mail'
          : '') || this.errorMessage.email},on:{"input":function($event){_vm.email = $event}}}),_c('textArea',{class:{
        error: _vm.validationStatus(_vm.$v.message) || this.errorMessage.message,
      },attrs:{"label":"Сообщение","field":_vm.message,"error":(!_vm.$v.message.required && _vm.$v.message.$error
          ? 'Обязательное поле'
          : '') || this.errorMessage.message},on:{"input":function($event){_vm.message = $event}}}),_vm._v(" "),_c('File',{attrs:{"chosenFileName":_vm.chosenFileName},on:{"submitMedia":_vm.submitMedia,"deleteFile":_vm.deleteFile}}),_c('div',{staticClass:"feedback-col feedback__checkbox_list"},[_c('checkBox',{class:{
          error: _vm.validationStatus(_vm.$v.checkbox) || _vm.errorMessage.checkbox,
        },attrs:{"label":"Подтверждаю, что мне больше 18 лет","field":_vm.checkbox,"error":_vm.errorMessage.checkbox},on:{"input":function($event){_vm.checkbox = $event}}}),_c('checkBox',{class:{
          error: _vm.validationStatus(_vm.$v.checkbox2) || _vm.errorMessage.checkbox2,
        },attrs:{"label":`Соглашаюсь с <a href='${_vm.publicPath}docs/rules.pdf' class='_undeline-link _bold' target='_blank'>Правилами участия</a> и <a href='${_vm.publicPath}docs/agreement.docx' class='_undeline-link _bold' target='_blank'> обработкой персональных данных</a>`,"field":_vm.checkbox2,"error":_vm.errorMessage.checkbox2},on:{"input":function($event){_vm.checkbox2 = $event}}})],1),_c('div',{staticClass:"feedback__footer"},[_c('button',{staticClass:"btn btn--primary btn--in-popup",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Отправить"),_c('img',{attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }