<template>
  <section class="section prizes">
    <div class="container">
      <div class="section-title">Призы</div>
      <div class="prizes-list">
        <div
          v-for="(item, i) in prizes"
          :key="i"
          class="prizes-list__item"
          @click="active = i"
          :class="{ _open: i === active }"
        >
          <img
            :src="require(`../assets/images/prizes/${item.img}`)"
            alt=""
            class="prizes-list__item-img"
          />
          <div class="prizes-list__item-content">
            <div class="prizes-list__item-type" v-html="item.type"></div>
            <div class="prizes-list__item-title" v-html="item.text"></div>
            <!-- <button @click="toCheckUpload" class="btn btn--bordered">
              Загрузить чек
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    active: null,
    prizes: [
      {
        img: "card-0.png",
        type: "Гарантированный приз",
        text: "500 бонусов <br>на&nbsp;карту X5&nbsp;Клуб",
      },
      {
        img: "card-1.png",
        type: "Еженедельный приз",
        text: "Капсула НЕО <br>или детский <br>планшет Digma",
      },
      {
        img: "card-2.png",
        type: "Главный приз",
        text: "Сертификат в&nbsp;парк развлечений &laquo;Сказка&raquo; номиналом 200&nbsp;000&nbsp;руб.",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.prizes {
  @media (min-width: $md) {
    margin-bottom: rem(40px);
  }
  &-list {
    display: flex;
    align-items: flex-start;
    width: calc(100% + rem(40px));
    margin-left: rem(-20px);
    gap: 0 rem(20px);
    flex-direction: column;
    @media (min-width: $md) {
      flex-direction: row;
      width: 100%;
      margin-left: 0;
    }
    &__item {
      position: relative;
      color: #fff;
      margin-bottom: rem(-300px);
      transition: 0.4s;
      &:last-child {
        margin-bottom: 0 !important;
      }
      &._open {
        margin-bottom: rem(-40px);
      }
      @media (min-width: $md) {
        flex: 0 1 33%;
        margin-bottom: 0;
      }
      &-content {
        position: absolute;
        top: rem(40px);
        left: rem(30px);
        max-width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &-img {
        width: 100%;
      }
      &-type {
        margin-bottom: rem(20px);
        padding: rem(7px) rem(10px);
        border-radius: rem(30px);
        background: rgba(#ffffff, 0.25);
        font-size: rem(12px);
        font-family: $font-light;
        line-height: 1;
      }
      &-title {
        width: 100%;
        margin-bottom: rem(20px);
        font-size: rem(28px);
        line-height: 1;
        font-family: $font-bold;
      }
    }
  }
}
</style>
